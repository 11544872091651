@import url("https://fonts.googleapis.com/css?family=Open+Sans|Roboto:400,700&display=swap");

body {
    font-family: "Inter", sans-serif;

}

.custom_outer {
    display: flex;
    margin: 50px auto;
    flex-wrap: wrap;
}

.custom_class {
    margin: 60px auto;
    min-width: 400px;
    width: 33.33%;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    color: whitesmoke;
    padding-left: 50px;
    padding-right: 50px;
    font-size: medium;

}

.custom_a {
    margin-top: -20px;
    background: transparent;
    width: 140px;
    height: 50px;
    font-size: 13px;
    font-weight: 700;
    border: 0.56px solid white;
    padding: 6px 5px;
    text-align: center;
    transition: 0.2s linear;
}

.custom_a:hover {
    background: rgb(98, 188, 244);
}

.custom_casino {
    margin: 0 auto;
}

.myclass {
    width: 100%;

}

.footerlogo {
    margin: auto 20px;
    max-width: 300px;
}

.cards_div_custom {
    flex-wrap: wrap;
    display: flex;
    padding: 10px 10px;
    padding-bottom: 50px;
}

.card_custom_outer {
    min-width: 200px;
    /* border: 1px solid yellow; */
    width: 400px;
    display: flex;
    flex-direction: column;
    margin: 10px auto;
    color: whitesmoke;
    position: relative;
}

.card_custom {

    display: flex;
    flex-direction: row;
}

.custom_card_image {
    min-width: 400px;
    height: 330px;
}

.mycontent_custom {
    text-align: center;
    min-height: 460px;
    margin-top: -80px;
}

.mycontent_custom h4 {
    font-weight: 700;
    font-size: x-large;
}

.mycontent_custom p {
    font-size: large;
    padding: 1px 5px;
}

.a_course {
    display: block;
    margin: 2px auto;

}

.new_margin {
    margin-top: 10px;
}

/* w-[60%] h-[150px] rounded-lg mx-10 inline-block */
.image_custom_class {
    width: 30%;
    height: 100px;
    border-radius: 20px;
    margin: 0 40px;
    display: inline-block;

}

.division {
    width: 600px;
    /* Full viewport width */
    height: 900px;
    /* border: 1px solid red; */
    margin-top: -650px;
}

.product-canvas {
    margin-top: -350px;
    height: 700px;
    width: 600px;
    cursor: pointer;

}

.scroller {
    margin: 0 auto;
    max-width: 90vw;

}

.scroller[data-animated="true"] {
    overflow: hidden;
    -webkit-mask: linear-gradient(90deg, transparent, white 10%, white 90%, transparent);
    mask: linear-gradient(90deg, transparent, white 10%, white 90%, transparent);
}

.scroller[data-animated="true"] .scroller_inner {
    flex-wrap: nowrap;
    animation: scroll 12s linear infinite;
}

.emergio_about {
    font-size: xx-large;
    font-weight: 700;
    margin-left: 40px;
}

.emergio_desc {
    margin-left: 40px;
    margin-top: 20px;
}

.mydiv {
    transition: 0.1s ease-in-out;
}

.mydiv:hover {
    background-color: #04596c;

}

/* hire section */
.title {
    margin-top: 15vh;
    margin-bottom: 4rem;
}

.title h2 {
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
    font-size: 48px;
    background: linear-gradient(45deg, #1051a6, #226fd4, #6a4c93);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-align: center;
    margin: 0;
    padding: 20px;

}

.section-center {
    display: flex;
    max-width: 80vw;
    flex-wrap: wrap;
    margin: 0 auto;
    justify-content: center;
}

.section-center article {
    margin: 6px;
}

.question {
    background: linear-gradient(45deg, #ff6b6b, #f06595, #6a4c93);
    border-radius: 4px;
    box-shadow: rgba(0, 0, 0, 0.5);
    padding: 1.5rem 1.5rem 0 1.5rem;
    margin-bottom: 3rem;
    cursor: pointer;
    font-weight: 600;
    margin-right: 10px;
    transition: background 0.3s ease-in-out, transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

.question:hover {
    background: linear-gradient(45deg, #6a4c93, #f06595, #ff6b6b);
    transform: scale(1.1);
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2);
}

.card {
    padding: 50px;
}

.question-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-transform: capitalize;
    padding-bottom: 1rem;
}

.question-title p {
    margin-bottom: 0;
    margin-right: 6px;
    letter-spacing: 2px;
    color: black;
}

.question-btn {
    font-size: 1.5rem;
    background: transparent;
    border-color: transparent;
    cursor: pointer;
    color: goldenrod;
    transition: 0.1s ease-in;
}

.question-btn:hover {
    transform: rotate(90deg);
}

.question-text {
    padding: 1rem 0 1.5rem 0;
    border-top: 1px solid orange;
}

.question-text p {
    margin-bottom: 0;
    color: black;
}

/* hide text */
.question-text {
    display: none;
}

.show-text .question-text {
    display: block;
}

.minus-icon {
    display: none;
}

.show-text .minus-icon {
    display: inline;
}

.show-text .plus-icon {
    display: none;
}

/* hiresection end */

@keyframes scroll {
    to {
        transform: translate(-50%);
    }
}

.scroller_inner {
    display: flex;
    gap: 1rem;
    padding-block: 1rem;
    flex-wrap: wrap;
}

@media only screen and (max-width: 768px) {
    .footer_custom {
        display: flex;
        flex-direction: column;

    }

    .footerlogo {
        margin: 10px auto;

    }

    .footer_content {
        margin: 10px auto;

    }
}

@media only screen and (max-width: 468px) {
    .footer_class_flex {
        display: flex;
    }

    .emergio_desc {
        margin-left: 2px;
    }
}

.newSec {
    color: whitesmoke;
    display: flex;

    margin: 100px 80px;
}

.newSec img {
    object-fit: contain;
}

.card-story {
    transition: 0.1s ease-in-out;
}

.card-story:hover {
    cursor: pointer;
    transform: scale(1.05);
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2);
}

@media (max-width: 975.98px) {
    .newSec {
        display: block;
    }
}

@media (max-width: 575.98px) {



    .title {
        margin-top: 10px;
    }

    .newSec img {
        margin-top: 50px;
        padding-left: 8px;
        padding-right: 8px;
    }

    .newSec {
        margin: 2px 30px;
    }

    .hide {
        display: none;
    }
}


.hire__btn {
    background: linear-gradient(90deg, blue 20%, rgb(9, 59, 185) 50%, blue 80%);
    padding: 6px 15px;
    border-radius: 40px;
    border: 2px solid white;
    color: white;
    font-size: x-small;
    font-weight: 600;
    transition: background 0.3s ease, transform 0.3s ease;
}

.hire__btn:hover {
    background: linear-gradient(90deg, blue, white);
    transform: scale(1.05);
}

.hire__div {
    max-width: 500px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 40px auto;

}

.hire__container {
    padding-top: 70px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    /* pt-[70px]  md:flex pb-24 flex-wrap  */
}

@media(max-width:1000px) {
    .hire__container {
        grid-template-columns: 1fr 1fr;
    }
}

@media(max-width:500px) {
    .hire__container {
        grid-template-columns: 1fr;
    }
}

.hire__div img {
    object-fit: cover;
    width: 460px;
}

.hire__div button {
    max-height: 40px;
    margin-left: -120px;
    margin-top: 140px;
}

/* "custom_a vdo-icon hover:bg-[#21C8ED] bg-transparent w-[140px] h-[50px]  text-[13px] font-[700] border-[0.568px] border-white mt-[2rem] hover:bg- transition-colors duration-500 text-white  py-2 px-2" */


/* temp ant */
.container {
    font-size: 16px;
    width: 90vw;
    max-width: 37.5em;
    padding: 3em 1.8em;
    background-color: #1e293b;
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    border-radius: 0.6em;
    box-shadow: 1.2em 2em 3em rgba(0, 0, 0, 0.2);
}

.search-container {
    display: grid;
    grid-template-columns: 9fr 3fr;
    gap: 1.3em;
}

.search-container input,
.search-container button {
    font-size: 0.9em;
    outline: none;
    border-radius: 0.3em;
}

.search-container input {
    background-color: transparent;
    border: 1px solid #a0a0a0;
    color: #fff;
    padding: 0.7em;
}

.search-container input:focus {
    border-color: #fff;
}

.search-container button {
    background-color: #ffb92a;
    border: none;
    cursor: pointer;
}

/*  */

.headContainer {
    color: azure;
    font-size: larger;
}

.headAligner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    margin: 20px;
}

.headLogo {
    font-size: 1.5em;
}

.headNav {
    display: flex;
    gap: 50px;
}

.ulElement {
    list-style: none;
    color: #fff;
    font-size: larger;
}

/* temp ant end */