.main-card{
   
    background-repeat: no-repeat;
    background-size: contain;
}
@media(max-width:768px) and (min-width:680px){
    .place-card img{
        margin-top: -4.5rem!important;
        height: 52vw;
    }  
}
@media(max-width:573px) and (min-width:435px){
    .place-card img{
        margin-top: -4.5rem!important;
        height: 65vw;
    }
    .place-card h2{
        font-size: 17px;
        margin-left: -3rem;
    }
    .place-card h3{
        font-size: 13px;
        margin-left: -2rem;
    }
    .place-card h5{
        font-size: 12px;
        margin-left: -4rem;
        
    }
}
@media(max-width:435px) and (min-width:360px){
    .main-card{
        margin-top: -5rem;
    }
    .place-card img{
        width: 220px;
        height: 53vw;
        margin-left: 2rem;
        margin-top: -.5rem!important;
    }
    .place-card h2{
        font-size: 17px;
        margin-left: -4rem;
    }
    .place-card h3{
        font-size: 12px;
        margin-left: -2rem;
    }
    .place-card h5{
        font-size: 9px;
        margin-left: -3.7rem;
        margin-top: -1px;
    }
}
@media(max-width:360px) and (min-width:320px){
    .main-card{
        margin-top: -5rem;
    }
    .place-card img{
        width: 220px;
        height: 57vw;
        margin-left: -2rem;
        margin-top: -1.5rem!important;
    }
    .place-card h2{
        font-size: 17px;
        margin-left: -2rem;
    }
    .place-card h3{
        font-size: 12px;
        margin-left: -1rem;
    }
    .place-card h5{
        font-size: 9px;
        margin-left: -3.7rem;
        margin-top: -1px;
    }
}