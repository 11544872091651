.game__div {
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: 50px 60px;
    background: rgb(51, 51, 51);
    color: whitesmoke;
    margin: 30px 50px;
}

.desc {
    display: flex;
    flex-direction: column;
    padding-right: 100px;
    justify-content: space-around;
    margin-left: 30px;
}

.desc h3 {
    font-size: 50px;
    font-weight: 700;
    color: #21C8ED;
}


.desc p {
    margin-top: -40px;
}

@media (max-width: 1280px) {
    .desc p {
        margin-top: 2px;
    }
}

@media (max-width: 1180px) {
    .desc {
        padding-right: 10px;
    }
}

@media (max-width: 1030px) {
    .game__div {
        display: flex;
        flex-direction: column;
        text-align: center;
    }


}

@media (max-width: 650px) {
    .desc h3 {
        font-size: 40px;
        font-weight: 600;
        color: #21C8ED;
    }
}

@media (max-width: 650px) {
    .game__div {
        margin: 20px 20px;
        padding: 30px 20px;
    }
}