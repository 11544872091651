.main {
    position: relative;
    font-family: "Inter", sans-serif;
    overflow-x: hidden;
    background-color: black;
    background-image: url(../Images/dragonbg.png);
    background-repeat: no-repeat;
    overflow-y: hidden;
    background-position: center top 80px;
    background-size: cover;
    width: 100%;
    height: auto;

}



.news-card,
.crs-img {
    border-radius: 13px;
    border: 1px solid #21C8ED;
    box-shadow: 0 0 10px #21C8ED;
    background: #080809;
}

.news-card h3 {
    color: #D9D9D9;

    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.news-card p {
    color: #D9D9D9 !important;


    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

@keyframes slideInFromLeft {
    0% {
        transform: translateX(-100%);
        opacity: 0;
    }

    100% {
        transform: translateX(0);
        opacity: 1;
    }
}

@keyframes slideInFromRight {
    0% {
        transform: translateX(100%);
        opacity: 0;
    }

    100% {
        transform: translateX(0);
        opacity: 1;
    }
}

.news-card {
    animation: slideInFromLeft 3s ease forwards;
}

.news-card:nth-child(odd) {
    animation-name: slideInFromRight;
}

.crs-cards p {
    color: #D9D9D9;

    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.ent-card-slider {
    border-radius: 9px;
    border: 1px solid #21C8ED;
    box-shadow: 0 0 10px #21C8ED;
    background: #080809;
}

.ent-card-slider button {
    border-radius: 7px;
    border: 1px solid #21C8ED;
    box-shadow: 0 0 10px #21C8ED;
    background: #080809;
}

.games-cat {
    position: relative;
    background-color: yellow;
    height: 400px;
    clip-path: polygon(0 0, 100% 0%, 53% 100%, 0% 100%);
}

.games-cat::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 26px;
    background-color: #21C8ED;
    z-index: 1;
}


.game-card h2,
.game-card2 h2 {
    color: #21C8ED;
}

.catagory {
    clip-path: polygon(0 0, 100% 0, 56% 100%, 0% 100%);

}

@media (max-width:1278px) {

    .game-card h2,
    .game-card2 h2 {
        font-size: 30px;
        margin-top: 2rem;
    }

    .game-card p,
    .game-card2 p {
        font-size: 12px;
        width: 75%;
    }

    .game-card img {
        width: 300px;
        height: 350px;
    }

    .game-card2 img {
        width: 280px;
        height: 280px;
        margin-left: -9rem;
    }


}

@media (max-width: 1024px) and (min-width: 932px) {

    .char-girl {
        margin-left: 30%;
        height: 350px;
    }


    .game-card h2,
    .game-card2 h2 {
        font-size: 28px;
        margin-top: 2rem;
    }

    .game-card p,
    .game-card2 p {
        font-size: 12px;
        width: 60%;
    }

    .game-card2 img {
        width: 220px;
        margin-left: -10rem;
    }

    .game-card img {
        width: 300px;
        height: 350px;
    }

    .game-card button,
    .game-card2 button {
        margin-top: -1;
    }

    .cat-bg img {
        height: 450px;

    }



}

@media (max-width: 931px) and (min-width: 860px) {

    .game-card h2,
    .game-card2 h2 {
        font-size: 28px;
        margin-top: 2rem;
    }

    .game-card p,
    .game-card2 p {
        font-size: 12px;
        width: 60%;
    }

    .game-card2 img {
        width: 200px;
        height: 200px;
        margin-top: 2rem;
        margin-left: -10rem;
    }

    .game-card img {
        width: 250px;
        height: 300px;
    }

    .game-card button,
    .game-card2 button {
        margin-top: -1;
    }

    .cat-bg img {
        height: 340px;
    }

    .cat-text {
        margin-left: -4rem;
        margin-top: -2rem;
    }

    .cat-text2 {
        margin-left: 0;
    }

}

@media (max-width: 859px) and (min-width: 785px) {


    .game-card h2,
    .game-card2 h2 {
        font-size: 28px;
        margin-top: 2rem;
    }

    .game-card p,
    .game-card2 p {
        font-size: 12px;
        width: 60%;
    }

    .game-card2 img {
        width: 200px;
        height: 200px;
        margin-top: 2rem;
        margin-left: -12rem !important;
    }

    .game-card img {
        width: 200px;
        height: 250px;
    }

    .game-card button,
    .game-card2 button {
        margin-top: -1;
    }

    .cat-bg img {
        height: 340px;
    }

    .cat-text {
        margin-left: -10rem;
        margin-top: -2rem;
    }

}

@media (max-width: 784px) and (min-width: 768px) {

    .char-girl {
        margin-left: 50%;
        height: 350px;
        margin-top: -25rem;
    }


    .game-card h2,
    .game-card2 h2 {
        font-size: 28px;
        margin-top: 2rem;
    }

    .game-card p,
    .game-card2 p {
        font-size: 12px;
        width: 60%;
    }

    .game-card2 img {
        width: 180px;
        height: 180px;
        margin-top: 2rem;
        margin-left: -12rem !important;
    }

    .game-card img {
        width: 200px;
        height: 250px;
    }

    .game-card button,
    .game-card2 button {
        margin-top: -1;
    }

    .cat-bg img {
        height: 340px;
    }

    .cat-text {
        margin-left: -10rem;
        margin-top: -2rem;
    }
}

@media (max-width: 768px) and (min-width: 654px) {

    .game-card h2,
    .game-card2 h2 {
        font-size: 25px;
        margin-top: 2rem;
    }

    .game-card p,
    .game-card2 p {
        font-size: 12px;
        width: 50%;
    }

    .game-card2 img {
        width: 180px;
        height: 180px;
        margin-top: 2rem;
        margin-left: -12rem !important;
    }

    .game-card img {
        width: 200px;
        height: 250px;
        margin-top: 1rem;
    }

    .game-card button,
    .game-card2 button {
        margin-top: -1;
    }

    .cat-bg img {
        height: 340px;
    }

    .cat-text {
        margin-left: -6rem;
        margin-top: -2rem;
    }
}

@media (max-width:654px) {
    .char-girl {
        display: none;
    }

    .game-card h2,
    .game-card2 h2 {
        font-size: 20px;
        margin-top: -1rem;
    }

    .game-card p,
    .game-card2 p {
        font-size: 8px;
        width: 50%;
    }

    .game-card img,
    .game-card2 img {
        width: 90px;
        height: 120px;
        margin-top: -1rem;
        margin-left: 2rem;
    }

    .game-card button {}

    .cat-bg img {
        height: 350px;
    }

    .cat-text {
        margin-left: 2rem;
    }

    .gamecard-main {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;

    }

    .gamecard-main2 {
        display: flex;
        flex-wrap: wrap-reverse;
        justify-content: center;
        align-items: center;
    }

    .game-card2 button {
        margin-left: 1rem;
    }
}

.slider-container::-webkit-scrollbar {
    display: none;

}

.game-card {

    background-repeat: no-repeat;
}

.catagory-left {}