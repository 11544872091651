.hireinput{
    /* background-image: url(../Images/hirebg.png); */
    background-repeat: no-repeat;
}
.numberplate{
    width: 293px;
height: 141px;
flex-shrink: 0;
border-radius: 21px;
border: 1px solid #21C8ED;
background: rgba(217, 217, 217, 0.23);
}
.hirebg2{
    background-image: url(../Images/hirebg1.png);
    background-repeat: no-repeat;
   
}
