.career-card {
    background-color: #0D0C0C;
    border-radius: 18px;
}

.position-card button {
    background-color: #21C8ED;
    border-radius: 9px;

}

.button-wrapper {
    position: relative;

    text-align: center;
    margin: 20% auto;
}

.button-wrapper span.label {
    position: relative;
    z-index: 0;
    display: inline-block;
    width: 100%;

    cursor: pointer;
    color: #fff;
    padding: 10px 0;
    text-transform: uppercase;
    font-size: 12px;
}

#upload {
    display: inline-block;
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 50px;
    top: 0;
    left: 0;
    opacity: 0;
    cursor: pointer;
}