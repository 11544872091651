.video-sec{
    background-image: none!important;
    background-color: black!important;
} 
.vdo-title{
    font-family: "Kanit", sans-serif;
}
.first {
    transition: transform 0.5s ease-in-out;
    transform: translateY(0);
 }
